var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fieldsetCounter, globals, id, validationId) {
      pug_mixins["input"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.type) {
attributes.type = 'text'
}
if (!attributes.attributes) {
attributes.attributes = {}
}
if (!attributes.groupAttributes) {
attributes.groupAttributes = {}
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": pug.classes(["form-group",attributes.groupHtmlClass], [false,false])},attributes.groupAttributes]), true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes(["control-label",attributes.labelHtmlClass], [false,true]), false, true)+pug.attr("for", id, true, true)) + "\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Flabel\u003E";
}
if (attributes.divTitle) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["control-label",attributes.divTitleHtmlClass], [false,true]), false, true)) + "\u003E" + (null == (pug_interp = attributes.divTitle) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes(["form-control",attributes.fieldHtmlClass], [false,true]),"id": pug.escape(id),"type": pug.escape(attributes.type),"placeholder": pug.escape(attributes.placeholder),"ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"name": pug.escape(id),"value": pug.escape(attributes.value),"required": pug.escape(attributes.required),"ng-pattern": pug.escape(attributes['ng-pattern']),"disabled": pug.escape(attributes.disabled),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E";
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (attributes.validationMessages) {
validationId = '"'+ id +'"'
if (attributes.validationId) {
validationId = attributes.validationId
}
pug_mixins["validationMessages"].call({
attributes: {"key": pug.escape(validationId),"validationMessages": pug.escape(attributes.validationMessages)}
});
}
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};


















































pug_mixins["checkbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
if (!attributes.attributes) {
attributes.attributes = {}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "checkbox schema-form-checkbox"},attributes.groupAttributes]), true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"class": pug.classes([attributes.fieldHtmlClass], [true]),"type": "checkbox","ng-model": pug.escape(attributes.key),"ng-disabled": pug.escape(attributes.readonly),"id": pug.escape(id),"name": pug.escape(id),"required": pug.escape(attributes.required),"ng-true-value": pug.escape(attributes['ng-true-value']),"ng-false-value": pug.escape(attributes['ng-false-value']),"data-cy": pug.escape(attributes['data-cy'])},attributes.attributes]), true)) + "\u003E\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["switchbox"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["double-label-switch",attributes.containerHtmlClass], [false,false]), false, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (pug.attr("class", pug.classes([attributes.labelHtmlClass], [true]), false, true)+pug.attr("for", id, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"switch round large\"\u003E\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+" type=\"checkbox\""+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("id", id, true, true)+pug.attr("name", id, true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-true-value", attributes['ng-true-value'], true, true)+pug.attr("ng-init", attributes['ng-init'], true, true)+pug.attr("ng-false-value", attributes['ng-false-value'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E\u003Clabel" + (" class=\"switch__label\""+pug.attr("for", id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};






































pug_mixins["radio"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.title && attributes.name) {
attributes.title = attributes.name
}
if (attributes.id) {
id = attributes.id
}
else {
id = attributes.key + '-' + attributes.index
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes([attributes.fieldHtmlClass], [true]), false, true)+pug.attr("id", id, true, true)+" type=\"radio\""+pug.attr("required", attributes.required, true, true)+pug.attr("name", attributes.key, true, true)+pug.attr("ng-model", attributes.key, true, true)+pug.attr("ng-disabled", attributes.readonly, true, true)+pug.attr("value", attributes.value, true, true)+pug.attr("ng-value", attributes['ng-value'], true, true)+pug.attr("data-cy", attributes['data-cy'], true, true)+pug.attr("ng-change", attributes['ng-change'], true, true)) + "\u003E";
if (attributes.title) {
pug_html = pug_html + "\u003Clabel" + (" class=\"radio-inline\""+pug.attr("for", attributes.key + '-' + attributes.index, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = attributes.title) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
};





























































pug_mixins["form"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (attributes.name) {
globals.formname = attributes.name
}
pug_html = pug_html + "\u003Cform" + (pug.attrs(pug.merge([{"novalidate": pug.escape(true)},attributes]), true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_mixins["validationMessages"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (!attributes.formname) {
attributes.formname = globals.formname
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-messages", attributes.formname +'['+ attributes.key +'].$error', false, true)) + "\u003E";
// iterate attributes.validationMessages
;(function(){
  var $$obj = attributes.validationMessages;
  if ('number' == typeof $$obj.length) {
      for (var condition = 0, $$l = $$obj.length; condition < $$l; condition++) {
        var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var condition in $$obj) {
      $$l++;
      var message = $$obj[condition];
pug_html = pug_html + "\u003Cdiv" + (" class=\"helpText\""+pug.attr("ng-message", condition, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
if (!fieldsetCounter) {
fieldsetCounter = 0
}





















pug_html = pug_html + "\u003Ch1\u003E\u003Ci class=\"font-icon icon-{{ $ctrl.componentId | bausteinIcon }}\"\u003E\u003C\u002Fi\u003E&nbsp; {{ $ctrl.componentId | bausteinTitle }}\u003C\u002Fh1\u003E\u003Cbr\u003E\u003Cbr\u003E\u003Cdiv overlay=\"$ctrl.loading.main\"\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_html = pug_html + "\u003Cdiv class=\"form-group\" ng-if=\"$ctrl.pst\"\u003E\u003Clabel for=\"componentpst\"\u003EPauschalsteuer\u003C\u002Flabel\u003E\u003Cselect id=\"componentpst\" ng-model=\"$ctrl.component.pst\"\u003E\u003Coption value=\"AG\"\u003EAG\u003C\u002Foption\u003E\u003Coption value=\"AN\"\u003EAN\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\" ng-if=\"$ctrl.is_sachbezug\"\u003E\u003Cdiv class=\"checkbox\"\u003E\u003Cinput type=\"checkbox\" ng-model=\"$ctrl.component.is_sachbezug\" id=\"componentis_sachbezug\"\u003E\u003Clabel for=\"componentis_sachbezug\"\u003E\u003Cspan\u003ESachbezug\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cdiv class=\"checkbox\"\u003E\u003Cinput type=\"checkbox\" ng-model=\"$ctrl.component.compensation_rente\" id=\"componentcompensation_rente\" readonly=\"!iAmSpecialAdvisor\"\u003E\u003Clabel for=\"componentcompensation_rente\"\u003E\u003Cspan\u003EWird zur Ermittlung der Rentenlücke herangezogen\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"checkbox\" ng-if=\"$ctrl.componentId == &quot;erholungsbeihilfe&quot;\"\u003E";
pug_mixins["checkbox"].call({
attributes: {"key": "$ctrl.component.spouse","title": "Erholungsbehilfe für Ehegatte"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"$ctrl.hasMaxValue\"\u003E\u003Chr\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\" ng-if=\"$ctrl.hasMaxValue\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-3 component-col\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.component.max_value","title": "Maximal Wert","type": "number","readonly": "!$ctrl.iAmSpecialAdvisor","validationMessages": {"required":"Bitte Eingabe prüfen","min":"\u003E1"},"attributes": {"ng-min":"1","ng-required":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-1\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-3 component-col no-label\"\u003EDefault Wert: {{ $ctrl.maxValue }}\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\" ng-if=\"$ctrl.hasMaxValue\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-3 component-col\"\u003E";
pug_mixins["input"].call({
attributes: {"key": "$ctrl.component.max_self_value","title": "Maximal Wert SelfService","type": "number","readonly": "!$ctrl.iAmSpecialAdvisor","validationMessages": {"required":"Bitte Eingabe prüfen","min":"\u003E1"},"attributes": {"ng-min":"1","ng-required":"true"}}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Chr\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.component.__vertragOptionen\"\u003E\u003Cdiv class=\"columns small-6 component-col no-label\"\u003EVertrag\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-3 component-col\"\u003E";
pug_mixins["radio"].call({
attributes: {"id": "$ctrl.component.vertrag_ag_anAG","key": "$ctrl.component.vertrag_ag_an","ng-value": "'AG'","readonly": "$ctrl.component.__vertragOptionen.length \u003C 2"}
});
pug_html = pug_html + "\u003Clabel for=\"component.vertrag_ag_anAG\"\u003EAG\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-3 component-col\"\u003E";
pug_mixins["radio"].call({
attributes: {"id": "$ctrl.component.vertrag_ag_anAN","key": "$ctrl.component.vertrag_ag_an","ng-value": "'AN'","readonly": "$ctrl.component.__vertragOptionen.length \u003C 2"}
});
pug_html = pug_html + "\u003Clabel for=\"component.vertrag_ag_anAN\"\u003EAN\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-3 component-col\"\u003E";
pug_mixins["checkbox"].call({
attributes: {"id": "component.no_rental","key": "$ctrl.component.no_rental"}
});
pug_html = pug_html + "\u003Clabel for=\"component.no_rental\"\u003EKein Miete\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-3 component-col\"\u003E&nbsp;\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.componentId === 'handy'\"\u003E\u003Cdiv class=\"columns small-6 component-col\"\u003EHandy + MPP\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-3\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"title": "Individuelle Auswahl über PC-Leasing erlauben","key": "$ctrl.component.settings.to_allow_handy_mpp","readonly": "!$ctrl.iAmSpecialAdvisor"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C!--.row(ng-if=\"$ctrl.componentId === 'essenscheck'\")--\u003E\u003C!--  .row--\u003E\u003C!--    .columns.small-6.component-col Default Type--\u003E\u003C!----\u003E\u003C!--  .row--\u003E\u003C!----\u003E\u003C!--    .columns.small-3.component-col(ng-repeat=\"essenscheck_type in $ctrl.AVAILABLE_ESSENSCHECK_TYPES\")--\u003E\u003C!--      +radio(--\u003E\u003C!--        id=\"essenscheckType{{essenscheck_type['id']}}\"--\u003E\u003C!--        key='$ctrl.component.type'--\u003E\u003C!--        ng-value=\"essenscheck_type['id']\"--\u003E\u003C!--        required--\u003E\u003C!--        title=\"{{essenscheck_type['display']}}\"--\u003E\u003C!--      )--\u003E\u003Cdiv class=\"row\" ng-if=\"$ctrl.componentId === &quot;pc&quot;\"\u003E\u003Cdiv class=\"columns small-6 component-col\"\u003EMPP Typ\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-3\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-6\"\u003E\u003Clabel for=\"component.useInternalMPPNeu\"\u003EIntern\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-6\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "component.useInternalMPPPNeu","key": "$ctrl.component.useInternalMPP","readonly": "!$ctrl.iAmSpecialAdvisor","ng-true-value": 1,"ng-false-value": 0}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"$ctrl.socialSecurityOptionsEnabled\"\u003E\u003Ch4\u003ESocial Security\u003Chr\u003E\u003C\u002Fh4\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"columns small-2 component-col\"\u003E";
globals = {}
pug_mixins["form"].call({
block: function(){
pug_mixins["switchbox"].call({
attributes: {"id": "socialSecuritySwitch","key": "$ctrl.component.social_security","ng-true-value": 1,"ng-false-value": 0}
});
pug_html = pug_html + "\u003Clabel for=\"socialSecuritySwitch\"\u003ESV-Pflichtig\u003C\u002Flabel\u003E";
},
attributes: {"name": "socialSecurityForm","id": "socialSecurityForm"}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Ch4\u003EVerwendung\u003C\u002Fh4\u003E\u003Chr\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" ng-repeat=\"usage in $ctrl.ngDialogData.neoChoicesDefault.list\"\u003E\u003Cdiv class=\"columns small-6 component-col\"\u003E";
pug_mixins["switchbox"].call({
attributes: {"id": "neoChoice{{usage}}Enabled","key": "$ctrl.neoChoice[usage].enabled","ng-change": "$ctrl.updateChoices(usage)"}
});
pug_html = pug_html + "\u003Clabel for=\"neoChoice{{usage}}Enabled\"\u003E{{ usage | usageTitle }}\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"columns small-6 component-col\" ng-if=\"$ctrl.neoChoice[usage].enabled\"\u003E";
pug_mixins["radio"].call({
attributes: {"id": "neoChoiceDefault{{usage}}","key": "$ctrl.ngDialogData.neoChoice.default","ng-change": "$ctrl.updateChoicesDefault()","ng-value": "usage"}
});
pug_html = pug_html + "\u003Clabel for=\"neoChoiceDefault{{usage}}\"\u003EStandard\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Chr\u003E";
},
attributes: {"name": "$ctrl.componentConfigForm"}
});
pug_html = pug_html + "\u003Cbutton type=\"button\" ng-click=\"$ctrl.close()\" ng-disabled=\"!$ctrl.componentConfigForm.$valid\"\u003ESchließen\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "fieldsetCounter" in locals_for_with ?
        locals_for_with.fieldsetCounter :
        typeof fieldsetCounter !== 'undefined' ? fieldsetCounter : undefined, "globals" in locals_for_with ?
        locals_for_with.globals :
        typeof globals !== 'undefined' ? globals : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "validationId" in locals_for_with ?
        locals_for_with.validationId :
        typeof validationId !== 'undefined' ? validationId : undefined));
    ;;return pug_html;};
module.exports = template;