import {COMPONENTS_WITH_OPTIONAL_SOCIAL_SECURITY} from '../../../services/components/componentsService';

const $inject = [
  '$q',
  '$scope',
  '$timeout',
  'Made',
  'vbmService',
  'authenticationService',
  'customerService',
  'componentsService',
  'lodash',
  'VSP_CONSTANTS'
];
export default class CockpitComponentDialogController {
  constructor(
    $q,
    $scope,
    $timeout,
    Made,
    vbmService,
    authenticationService,
    customerService,
    componentsService,
    lodash,
    VSP_CONSTANTS
  ) {
    Object.assign(this, {
      $q,
      $scope,
      $timeout,
      Made,
      vbmService,
      authenticationService,
      customerService,
      componentsService,
      lodash,
      VSP_CONSTANTS
    });

    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');

    this.ngDialogData = $scope.ngDialogData;
    this.component = this.ngDialogData.component;
    this.componentId = this.ngDialogData.componentId;
    this.schema = {};
    this.pst = false;
    this.neoChoice = {};
    this.loading = {
      main: true
    };

    this.AVAILABLE_ESSENSCHECK_TYPES = Object.values(this.lodash.omit(
      this.VSP_CONSTANTS.ESSENSCHECK_TYPE,
      ['OLD_NEO_PROJECT_DEFAULT', 'NEW_NEO_PROJECT_DEFAULT', 'CALCULATION_DEFAULT']
    )).sort((firstEl, secondEl) => {
      if (firstEl.id < secondEl.id) {
        return -1;
      } else if (firstEl.id === secondEl.id) {
        return 0;
      } else {
        return 1;
      }
    });

    for (let usage of this.ngDialogData.neoChoice.list) {

      this.neoChoice[usage] = {
        enabled: true
      };
    }

    this.custId = parseInt(location.pathname.split('/')[3]);
    this.neoProjectId = location.search.replace('?neoProjectId=', '');

    if (this.componentId === 'pc' && this.component.useInternalMPP === 1 && typeof this.component.usedlimit == 'undefined') {
      this.component.usedlimit = 0;
    }

    this.socialSecurityOptionsEnabled = COMPONENTS_WITH_OPTIONAL_SOCIAL_SECURITY.includes(this.componentId);

    // attach settings object to handy
    if (this.componentId === 'handy') {
      this.component.settings = Object.assign({}, this.component.settings);
      delete this.component.allow_handy_mpp;
      delete this.component.to_allow_handy_mpp;
    }

    // attach essenschecks default type
    if (this.componentId === 'essenscheck') {
      if (angular.isUndefined(this.component.type)) {
        this.component.type = this.VSP_CONSTANTS.ESSENSCHECK_TYPE.NEW_NEO_PROJECT_DEFAULT.id;
      }
    }

    this.$q
      .all({
        components: this.vbmService.getAvailableNeoComponents(),
        pstEnabledComponents: this.vbmService.getPstEnabledComponents(),
        maxValue: this.componentsService.getMaxValues($scope.componentId)
      })
      .then(result => {
        [
          'anzahl_gutscheine',
          'anzahl_km',
          'anzahl_tage',
          'anzahl_std_abwesend',
          'cent_pro_km'
        ]
          .forEach(property => {
            if (this.vbmService.fullComponents.neo_components.properties[this.componentId].properties[property]) {
              this.schema[property] = true;
            }
          });

        if (this.vbmService.fullComponents.neo_components.properties[this.componentId].properties.is_sachbezug) {
          this.is_sachbezug = true;
          if (!this.component.is_sachbezug) {
            this.component.is_sachbezug = false;
          }
        }

        if (typeof result.maxValue[this.componentId] !== 'undefined') {
          if (typeof this.component.max_value === 'undefined') {
            this.component.max_value = result.maxValue[this.componentId];
          }
          if (typeof this.component.max_self_value === 'undefined') {
            this.component.max_self_value = result.maxValue[this.componentId];
          }
          this.hasMaxValue = true;
          this.maxValue = result.maxValue[this.componentId];
        }

        if (result.pstEnabledComponents.hasOwnProperty(this.componentId)) {
          this.pst = result.pstEnabledComponents[this.componentId];
        } else if (this.vbmService.fullComponents.neo_components.properties[this.componentId].properties.pst) {
          this.pst = true;
        }
        if (this.pst && !this.component.pst) {
          this.component.pst = 'AG';
        }

        if (!Object.keys(this.schema).length) {
          this.schema.value = true;
        }
      }).then(() => {
      this.$timeout(() => {
        this.loading.main = false;
      });
    });
  }

  close() {
    this.$scope.closeThisDialog();
  }

  updateChoicesDefault() {
    if (this.componentId === 'essenscheck') {
      this.component.type = this.VSP_CONSTANTS.ESSENSCHECK_TYPE_BY_NAME[this.ngDialogData.neoChoice.default]['id'];
    }
  }

  updateChoices(selectedUsage) {
    this.ngDialogData.neoChoice.list = [];

    if (this.ngDialogData.neoChoice.default === selectedUsage) {
      this.neoChoice[selectedUsage].enabled = true;
    }

    for (let usage in this.neoChoice) {
      if (this.neoChoice[usage].enabled) {
        this.ngDialogData.neoChoice.list.push(usage);
      }
    }

    if (!this.ngDialogData.neoChoice.list.length) {
      this.neoChoice[this.ngDialogData.neoChoice.default] = {
        enabled: true
      };
      this.updateChoices();
    }


  };

  checkMPP() {
    this.customerService.checkMPPStatus(this.custId).then(mpp => {
      this.component.useInternalMPP = mpp.useInternalMPP;
      this.component.useNewCalculation = mpp.useNewCalculation;
    });

  };

}
CockpitComponentDialogController.$inject = $inject;

angular
  .module('VSPApp')
  .controller('CockpitComponentDialogController', CockpitComponentDialogController);
